<template>
  <div>
    <select-web-service
      v-if="isCreateWebService"
      style="margin-top: 34px;width: 100%"
      v-loading="loadingProfiles"
      :web-profiles="filteredWebProfiles"
      @action-selected="handleSelect"
      @action-canceled="handleCancel"
    ></select-web-service>
    <div v-else>
      <transition>
        <div
          style="position: absolute;left: 10px;top: 10px;z-index: 1000;"
          v-if="!isEdit"
        >
          <el-button
            icon="el-icon-arrow-left"
            plain
            text
            class="backBtn"
            @click="handleBackBtnClick"
            >{{ __("Back") }}
          </el-button>
        </div>
      </transition>

      <el-form
        ref="nodeForm"
        label-position="top"
        :rules="rules"
        :model="nodeToBind"
        :hide-required-asterisk="false"
      >
        <div class="primary-title" style="margin-top:20px;margin-bottom:15px">
          {{ getNodeName() }}
        </div>

        <el-form-item prop="node_name" :label="__('Name')">
          <el-input v-model="nodeToBind.node_name"></el-input>
        </el-form-item>

        <!-- Selected Profile settings fields -->
        <template>
          <tone-analyzer-node
            v-if="selectedProfile.action_name === 'tone_analyzer'"
            :selected-profile="selectedProfile"
            :node-to-bind="nodeToBind"
            :append-to-post-body="addPostBodyRawParameter"
          />
          <DialogFlowNode
            v-if="selectedProfile.action_name === 'dialogflow'"
            :selected-profile="selectedProfile"
            :node-to-bind="nodeToBind"
            :file-url="jsonFileUrl"
            :append-to-post-body="addPostBodyRawParameter"
            @update-file-url="updateJsonFileUrl"
            @delete-file-url="updateJsonFileUrl"
            :task-id="task_id"
          />
          <wfa-node
            v-if="selectedProfile.action_name === 'five9_wfa'"
            :selected-profile="selectedProfile"
            :node-to-bind="nodeToBind"
            :append-to-post-body="addPostBodyRawParameter"
            :task-id="task_id"
            @updated-catch-url="handleCatchUrlChange"
            @updated-variable-rules="handleVariableRulesChange"
            :is-editing="isEdit"
          />
          <open-ai-node
            v-if="selectedProfile.action_name === 'open_ai'"
            :selected-profile="selectedProfile"
            :node-to-bind="nodeToBind"
            :is-editing="isEdit"
            :task-id="task_id"
            :music-on-hold="musicOnHold"
            :music-on-hold-url="musicOnHoldUrl"
            :music-on-hold-name="musicOnHoldName"
            :append-to-post-body="addPostBodyRawParameter"
            @upload-moh-file-success="handleMOHUploadSuccess"
            @remove-moh-file="handleMOHFileRemove"
            @add-rule="addOpenAIRule"
          />
        </template>
        <!-- Common/Shared Fields -->
        <template v-if="!this.isOpenAINode">
          <el-form-item
            prop="web_service_node.data.music_on_hold"
            :label="__('Music On Hold')"
            style="margin-top: 20px;"
            v-if="this.task_type && this.task_type === 'voice'"
          >
            <wav-file-uploader
              :folder="`tasks/${task_id}/web_service_node/moh`"
              :file-url="musicOnHoldUrl"
              :file-name="musicOnHoldName"
              :max-size-in-mb="3"
              @on-success="handleMOHUploadSuccess"
              @on-delete="handleMOHFileRemove"
            />
          </el-form-item>
          <el-form-item
            prop="web_service_node.data.fetch_timeout"
            :label="__('Fetch Timeout')"
          >
            <el-input-number
              class="fetchTimeoutSetting"
              v-model="nodeToBind.web_service_node.data.fetch_timeout"
              :min="3"
              :max="180"
            ></el-input-number>
            <span style="margin-left: 10px;">{{ __("seconds") }}</span>
          </el-form-item>
        </template>

        <template v-if="!isOpenAISummaryUseCase">
          <web-service-response
            :node-to-bind="nodeToBind"
            :items="variables"
            :http-response="httpResponse"
            :variable-rules="variableRules"
            :custom-url="customUrl"
            :array-rule="arrayRule"
            @remove-rule="removeRule"
            @add-rule="addRule"
            @update-response="updateHttpResponse"
          />
        </template>

        <el-row type="flex">
          <el-col
            :span="12"
            style="display: flex; align-items: flex-start;margin-top:20px;"
          >
            <el-button
              :loading="isNodeSubmit"
              :disabled="
                isTaskReadOnly ||
                  (linkedOrphanNode === -1 && isEdit && !nodeUpdated)
              "
              @click="handleSaveNodeClick"
              class="submitBtn"
            >
              {{
                linkedOrphanNode !== -1
                  ? __("Insert")
                  : isEdit
                  ? __("Save Changes")
                  : __("Create")
              }}
            </el-button>
            <el-button @click="handleCancel" class="cancelBtn"
              >{{ __("Cancel") }}
            </el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <!--    </transition>-->
  </div>
</template>

<script>
import _ from "lodash";
import BaseNode from "../BaseNode";
import SelectWebService from "./SelectWebService";
import ToneAnalyzerNode from "./ToneAnalyzerNode";
import DialogFlowNode from "./DialogFlowNode";
import OpenAiNode from "./OpenAiNode";
import WfaNode from "./WfaNode";
import WebServiceResponse from "../components/WebServiceResponse";

import { mapActions, mapGetters, mapState } from "vuex";
import { filterRowsIfSomeKeyValueIsAbsent } from "@/utils/collection";
import { NODE_TYPES } from "@/constants/nodes";
import WavFileUploader from "@/components/uploaders/types/WavFileUploader";
import { removeFiles } from "@/api/services";
import path from "path";
import WebservicesConstants, {
  OPEN_AI_CUSTOM_PROMPTING_USE_CASE,
  OPEN_AI_SUMMARY_USE_CASE,
  webServiceNodeDisplayName
} from "./WebservicesConstants";

export default {
  mixins: [BaseNode],
  components: {
    WfaNode,
    SelectWebService,
    ToneAnalyzerNode,
    DialogFlowNode,
    OpenAiNode,
    WebServiceResponse,
    WavFileUploader
  },
  data() {
    /**
     * Check if the query input field is required
     */
    const isQueryInputRequired = () => {
      if (!this.isDialogflowNode) {
        return true;
      }

      return this.isDialogflowNodeTextInput;
    };

    const validateMessageJson = (rule, value, cb) => {
      if (!value) {
        cb();
      } else if (this.hasInvalidVariable(value)) {
        cb(__("Invalid variables found in Json content"));
      } else {
        try {
          value = _.isObject(value) ? JSON.stringify(value) : value;
          JSON.parse(value);
        } catch (e) {
          cb(__("JSON format is invalid"));
          return;
        }
        cb();
      }
    };

    const validateQueryParams = (rule, value, cb) => {
      if (!value) {
        cb();
      } else if (this.hasInvalidVariable(value)) {
        cb(__("Invalid variables found in Query Params"));
      } else if (value.length > 1000) {
        cb(__("Query params should be no more than 1000 characters"));
      } else {
        cb();
      }
    };

    /**
     * Check if the dialogflow event input field is required
     */
    const isDialogflowEventInputRequired = () => {
      if (!this.isDialogflowNode) {
        return false;
      }
      return !this.isDialogflowNodeTextInput;
    };

    const validateEventInputName = (rule, value, cb) => {
      if (!value) {
        cb(__("Dialogflow EventInput name is required"));
      } else if (this.hasInvalidVariable(value)) {
        cb(__("Invalid variables found in Event Input Name"));
      } else if (value.length > 1000) {
        cb(__("Query params should be no more than 1000 characters"));
      } else {
        cb();
      }
    };

    const validateEventInputParameter = (rule, value, cb) => {
      if (!value) {
        cb(__("Dialogflow EventInput parameters is required"));
      } else if (this.hasInvalidVariable(value)) {
        cb(__("Invalid variables found in Event Input Parameter"));
      } else if (value.length > 1000) {
        cb(__("EventInput parameters should be no more than 1000 characters"));
      } else {
        cb();
      }
    };

    return {
      filesUploaded: [],
      musicOnHoldUrl: "",
      jsonFileUrl: "",
      customUrl: "",
      loadingProfiles: true,
      webProfiles: [],
      httpResponse: {},
      variableRulesBckup: [],
      linkedOrphanNode: -1,
      transitionName: "slide-fade",
      showOpenAiProfile: false,
      rules: {
        "web_service_node.data.input_text": [
          {
            required: isQueryInputRequired,
            message: __("Please select a query input text"),
            trigger: "change"
          },
          {
            max: 500,
            message: __("Text should be no more than 500 characters"),
            trigger: "change"
          }
        ],
        "web_service_node.data.additional_settings.file_details.file_url": {
          required: true,
          message: __("Please upload a service account file"),
          trigger: "change"
        },
        "web_service_node.data.additional_settings.project_id": {
          required: true,
          message: __("Please enter a project-id"),
          trigger: "change"
        },
        "web_service_node.data.additional_settings.lang": {
          required: true,
          message: __("Please select a language"),
          trigger: "change"
        },
        "web_service_node.data.additional_settings.auth_profile": {
          required: true,
          message: __("Please select an Authentication"),
          trigger: "change"
        },
        "web_service_node.data.additional_settings.webhook_guid": {
          required: true,
          message: __("Please select a webhook"),
          trigger: "change"
        },
        "web_service_node.data.additional_settings.body": {
          validator: validateMessageJson,
          trigger: "change"
        },
        "web_service_node.data.additional_settings.query_params_json": {
          validator: validateQueryParams,
          trigger: "blur"
        },
        "web_service_node.data.additional_settings.input_type": {
          required: true,
          trigger: "change"
        },
        "web_service_node.data.additional_settings.event_input_name": {
          required: isDialogflowEventInputRequired,
          validator: validateEventInputName,
          trigger: "blur"
        },
        "web_service_node.data.additional_settings.event_input_parameters": {
          required: isDialogflowEventInputRequired,
          validator: validateEventInputParameter,
          trigger: "blur"
        },
        "web_service_node.data.additional_settings.open_ai.api_key": {
          required: true,
          message: __("API Key is required"),
          trigger: "change"
        },
        "web_service_node.data.additional_settings.open_ai.model": {
          required: true,
          message: __("Model is required"),
          trigger: "change"
        }
      }
    };
  },

  computed: {
    ...mapState("webserviceprofiles", {
      webServiceProfiles: state => state.webServiceProfiles,
      webServiceProfilesLoading: state => state.isLoading
    }),

    ...mapState("canvas", {
      isEdit: state => state.isEdit,
      nodeUpdated: state => state.nodeUpdated
    }),

    ...mapGetters("canvas", {
      isTaskReadOnly: "isTaskReadOnly"
    }),

    isCreateWebService() {
      return this.selectedProfile === "";
    },
    selectedProfileType() {
      return !_.isEmpty(this.selectedProfile)
        ? _.get(this.selectedProfile, "action_name", "")
        : "";
    },

    filteredWebProfiles() {
      if (!this.showOpenAiProfile) {
        return _.filter(
          this.webProfiles,
          profile => profile.action_name !== WebservicesConstants.OPEN_AI_ACTION
        );
      }
      return this.webProfiles;
    },
    selectedProfile: {
      get: function() {
        const data = this.webProfiles;
        return _.isEmpty(data)
          ? ""
          : this.findSelectedProfile(_.cloneDeep(data));
      },
      set: function(selectedProfile) {
        if (
          // append otherwise node to keyword conditions only if otherwise node content changes
          !_.isEqual(this.selectedProfile, selectedProfile)
        ) {
          this.$set(
            this.nodeToBind.web_service_node.data,
            "web_service_profile_id",
            selectedProfile.web_service_profile_id || null
          );
          this.$set(
            this.nodeToBind.web_service_node.data,
            "action_name",
            selectedProfile.action_name
          );
        }
      }
    },

    isOpenAISummaryUseCase() {
      return (
        this.isOpenAINode &&
        _.get(
          this.nodeToBind,
          "web_service_node.data.additional_settings.open_ai.usecase"
        ) === OPEN_AI_SUMMARY_USE_CASE
      );
    },

    /**
     * Check if the selected web service is the dialogflow service
     */
    isDialogflowNode() {
      return (
        this.selectedProfile.action_name === WebservicesConstants.DIALOGFLOW
      );
    },

    /**
     * Check if the selected web service is the Open AI service
     */
    isOpenAINode() {
      return (
        this.selectedProfile.action_name === WebservicesConstants.OPEN_AI_ACTION
      );
    },

    /**
     * Check if the selected web service is the dialogflow service using text input
     */
    isDialogflowNodeTextInput() {
      return (
        _.get(
          this.nodeToBind,
          "web_service_node.data.additional_settings.input_type"
        ) === WebservicesConstants.DIALOGFLOW_TEXT_INPUT
      );
    },

    webServiceProfileSettings() {
      if (this.nodeToBind.web_service_node.data.web_service_profile_id) {
        let obj = this.webServiceProfiles;
        let result = _.result(
          _.find(obj, webProfile => {
            return (
              webProfile.web_service_profile_id ===
              this.nodeToBind.web_service_node.data.web_service_profile_id
            );
          }),
          "profile_setting"
        );
        if (result) {
          return JSON.parse(result);
        }
      }
      return {};
    },

    variableRules() {
      return _.isEmpty(this.nodeToBind.web_service_node) ||
        _.isEmpty(this.nodeToBind.web_service_node.data) ||
        _.isEmpty(this.nodeToBind.web_service_node.data.variable_rules)
        ? []
        : this.nodeToBind.web_service_node.data.variable_rules.data || [];
    },
    musicOnHold: {
      get() {
        return this.nodeToBind.web_service_node.data.music_on_hold;
      },
      set(path) {
        this.$set(this.nodeToBind.web_service_node.data, "music_on_hold", path);
      }
    },
    musicOnHoldName() {
      return this.musicOnHold ? this.getTailName(this.musicOnHold) : "";
    }
  },

  created() {
    this.initializeWebServiceNode();
    this.initializeWebServiceProfiles();
  },

  methods: {
    ...mapActions("webserviceprofiles", {
      getWebServiceProfiles: "getAcWebServiceProfiles"
    }),
    ...mapActions("upload", {
      deleteFileFromStorage: "deleteLogo"
    }),
    /**
     *  Toggle Open AI Feature visibility using LD feature flag
     */
    async toggleOpenAIFeatureVisibility() {
      this.showOpenAiProfile = await this.showFeature(
        this.$getConst("OPEN_AI_WS_INTEGRATION")
      );
    },

    removeRule(rule) {
      this.variableRules.splice(this.variableRules.indexOf(rule), 1);
    },

    addRule(rule) {
      this.nodeToBind.web_service_node.data.variable_rules.data.push(rule);
    },

    addOpenAIRule({ rule, ruleValue }) {
      if (_.isEmpty(this.nodeToBind.web_service_node.data.variable_rules)) {
        this.$set(this.nodeToBind.web_service_node.data, "variable_rules", {});
        this.$set(
          this.nodeToBind.web_service_node.data.variable_rules,
          "data",
          []
        );
      } else {
        _.remove(
          this.nodeToBind.web_service_node.data.variable_rules.data,
          obj => obj.rule_value === ruleValue
        );
      }
      this.nodeToBind.web_service_node.data.variable_rules.data.push(rule);
    },

    /**
     * After delete music on hold clear up the variables
     */
    handleMOHFileRemove() {
      this.musicOnHold = "";
      this.musicOnHoldUrl = "";
    },
    /**
     * After upload music on hold clear up the variables
     */
    handleMOHUploadSuccess({ path, url }) {
      this.musicOnHold = path;
      this.musicOnHoldUrl = url;
      this.pushToFilesUploaded(path);
    },
    getName(p) {
      return path.basename(p);
    },
    getTailName(p) {
      return _(_.split(this.getName(p), "_"))
        .tail()
        .value()
        .join("_");
    },
    handleCancel() {
      this.$emit("handleCancel");
    },
    updateJsonFileUrl(keyFileUrl, path) {
      this.jsonFileUrl = keyFileUrl;

      this.$set(
        this.nodeToBind.web_service_node.data.additional_settings.file_details,
        "file_url",
        keyFileUrl
      );

      this.$set(
        this.nodeToBind.web_service_node.data.additional_settings.file_details,
        "file_path",
        path
      );

      this.$refs["nodeForm"].validate();
    },
    handleCatchUrlChange(val) {
      this.customUrl = val;
    },
    handleVariableRulesChange(variableRules) {
      this.$set(
        this.nodeToBind.web_service_node.data.variable_rules,
        "data",
        variableRules
      );
      // update http response
      if (this.arrayRule().default_value) {
        this.httpResponse = JSON.parse(this.arrayRule().default_value);
      }
    },
    getNodeName() {
      let displayName = this.selectedProfile.display_name;
      if (this.selectedProfileType.toLowerCase() === "five9_wfa") {
        displayName = displayName.toUpperCase();
      }
      return __(`${displayName} ${__("Node")}`);
    },
    handleSaveNodeClick() {
      this.updateNodeNameCopy(null);

      if (!this.isTaskReadOnly) {
        this.linkedOrphanNode !== -1
          ? this.linkOrphanNode()
          : this.toggleNodeSubmit(true);
      } else {
        this.$message.error(__("Not Permitted!"));
      }
    },

    handleSelect(value) {
      this.selectedProfile = value;
    },
    handleBackBtnClick() {
      // Reset profile and json httpResponse
      this.selectedProfile = {};
      this.httpResponse = {};
      this.initializeWebServiceNode();
    },
    findSelectedProfile(profiles) {
      // Check if the profile is selected while editing
      const condition = _.cloneDeep(
        _.find(profiles, profile => {
          return (
            profile.web_service_profile_id ===
            this.nodeToBind.web_service_node.data.web_service_profile_id
          );
        }) || {}
      );
      // Pick the condition with respect to selected profile or by default return first item in the list
      return _.isEmpty(condition) ? "" : condition;
    },
    arrayRule() {
      let arrayRule = _.find(
        this.variableRules,
        rule => rule.variable_name === this.arrayVariableName
      );
      let { variable_name, variable_id } = this.arrayVariable;
      if (!arrayRule) {
        arrayRule = {
          rule_value: "root",
          default_value: "",
          variable_name,
          variable_id,
          array_variable: true
        };
        this.nodeToBind.web_service_node.data.variable_rules.data.push(
          arrayRule
        );
      }
      return arrayRule;
    },
    async initializeWebServiceProfiles() {
      await this.getWebServiceProfiles();
      this.loadingProfiles = this.webServiceProfilesLoading;
      let webProfiles = _.mapValues(this.webServiceProfiles, value => {
        value.display_name = webServiceNodeDisplayName(value.action_name);
        return value;
      });
      if (webProfiles !== undefined) {
        this.webProfiles = Object.values(webProfiles);
        this.loadingProfiles = false;
      }
    },
    addPostBodyRawParameter(parameter) {
      if (!_.isEmpty(parameter)) {
        if (
          _.isEmpty(
            _.get(
              this.nodeToBind.web_service_node.data,
              "post_body_placeholder"
            )
          )
        ) {
          this.$set(
            this.nodeToBind.web_service_node.data,
            "post_body_placeholder",
            {}
          );
        }
        if (
          this.selectedProfileType === "five9_wfa" ||
          this.selectedProfileType === WebservicesConstants.OPEN_AI_ACTION
        ) {
          this.$set(
            this.nodeToBind.web_service_node.data.post_body_placeholder,
            "body_type",
            "raw"
          );
          this.$set(
            this.nodeToBind.web_service_node.data.post_body_placeholder,
            "body_raw",
            parameter
          );
        } else {
          this.$set(
            this.nodeToBind.web_service_node.data.post_body_placeholder,
            "input_text",
            parameter
          );
        }
      }
    },
    updateHttpResponse(httpResponse) {
      this.httpResponse = httpResponse;
    },
    pushToFilesUploaded(path) {
      !_.includes(this.filesUploaded, path) && this.filesUploaded.push(path);
    },

    initializeWebServiceNode() {
      if (
        !this.nodeToBind.node_id ||
        _.isEmpty(this.nodeToBind.web_service_node)
      ) {
        this.$set(this.nodeToBind, "web_service_node", {});
        this.$set(this.nodeToBind.web_service_node, "data", {
          fetch_timeout: 60,
          input_text: "",
          web_service_profile_id: null,
          variable_rules: {}
        });
        this.$set(
          this.nodeToBind.web_service_node.data.variable_rules,
          "data",
          []
        );
        this.$set(
          this.nodeToBind.web_service_node.data,
          "additional_settings",
          {
            file_details: {
              file_url: "",
              file_path: ""
            },
            project_id: "",
            lang: "",
            auth_profile: "",
            webhook_guid: "",
            capability_name: "",
            query_params_json: "",
            input_type: "",
            event_input_name: "",
            event_input_parameters: "",
            open_ai: {
              api_key: "",
              model: "",
              messages_json: "",
              temperature: 0,
              max_tokens: 2048,
              top_p: 0,
              presence_penalty: 0,
              frequency_penalty: 0,
              usecase: OPEN_AI_CUSTOM_PROMPTING_USE_CASE,
              detect_multiple_intents: false,
              intent_user_prompt: "",
              intents: [],
              data_store_id: "",
              question_column: null,
              answer_column: null,
              summary_language: ""
            },
            body: {
              id: "",
              data: {}
            },
            to_wait: false,
            to_wrap: false
          }
        );

        //initialize for the post body data
        this.$set(
          this.nodeToBind.web_service_node.data,
          "post_body_placeholder",
          {}
        );

        this.$set(
          this.nodeToBind,
          "node_type",
          NODE_TYPES.WEB_SERVICE.NODE_TYPE
        );
        this.$set(this.nodeToBind.web_service_node.data, "music_on_hold", "");
      } else {
        this.musicOnHold =
          this.nodeToBind.web_service_node.data.music_on_hold || "";
        this.musicOnHoldUrl = this.nodeToBind.web_service_node.data.music_on_hold_url;

        if (_.isEmpty(this.nodeToBind.web_service_node.data.variable_rules)) {
          this.$set(
            this.nodeToBind.web_service_node.data,
            "variable_rules",
            {}
          );
          this.$set(
            this.nodeToBind.web_service_node.data.variable_rules,
            "data",
            []
          );
        }
        if (
          _.isEmpty(this.nodeToBind.web_service_node.data.additional_settings)
        ) {
          this.$set(
            this.nodeToBind.web_service_node.data,
            "additional_settings",
            {
              file_details: {
                file_url: "",
                file_path: ""
              },
              project_id: "",
              lang: ""
            }
          );
        } else {
          // loading additional settings
          // const additional_settings = JSON.parse(
          //   this.nodeToBind.web_service_node.data.additional_settings
          // );
          const additional_settings = this.nodeToBind.web_service_node.data
            .additional_settings;
          // this.$set(
          //   this.nodeToBind.web_service_node.data,
          //   "add_settings_placeholder",
          //   additional_settings
          // );
          // Load the file upload url
          this.jsonFileUrl = additional_settings.file_details.file_url;
        }
        //initialize for the post body data (input text)
        this.$set(
          this.nodeToBind.web_service_node.data,
          "post_body_placeholder",
          {}
        );

        this.addPostBodyRawParameter(
          this.nodeToBind.web_service_node.data.input_text
        );
      }
      // Handle variable rules
      this.nodeToBind.web_service_node.data.variable_rules.data = _.map(
        this.variableRules,
        rule => ({
          ...rule,
          array_variable: this.isVariableOfTypeArray(rule.variable_id)
        })
      );

      if (this.arrayRule().default_value) {
        this.httpResponse = JSON.parse(this.arrayRule().default_value);
      }
    },

    reInitializeVariableRules() {
      return _.map(this.variableRules, rule => ({
        rule_value: rule.rule_value,
        variable_name: rule.variable_name,
        variable_id: rule.variable_id,
        default_value: rule.default_value || "",
        variable_type: rule.variable_type,
        description: rule.description,
        import_configurable: rule.import_configurable
      }));
    },

    isVariableOfTypeArray(id) {
      let variable = _.find(
        this.variables,
        variable => variable.variable_id === id
      );
      if (!variable) {
        return false;
      }
      return variable.variable_type === "array";
    },
    removeUnnecessaryUploadedFiles(action = "submit") {
      let filesToRemove = this.filesUploaded;
      if (action === "submit") {
        // removes all the uploaded files which are not the currently set files
        // find files used in form_data of request

        let multiPartFilesUsed = _(this.formData)
          .filter(item => item.type === "file" && item.value)
          .map("value")
          .value();

        let filesInUse = [...multiPartFilesUsed, this.musicOnHold];

        filesToRemove = _.reject(filesToRemove, file =>
          filesInUse.includes(file)
        );
      } else {
        // removes all uploaded files
        filesToRemove = this.filesUploaded;
      }

      removeFiles(filesToRemove);
      this.filesUploaded = [];
    },
    cleanUpNodeToPrepareForSubmit() {
      const nodeToCleanUp = _.cloneDeep(this.nodeToBind);
      this.variableRulesBckup = _.cloneDeep(this.variableRules);
      this.removeUnnecessaryUploadedFiles("submit");
      try {
        nodeToCleanUp.web_service_node.data.variable_rules.data = this.reInitializeVariableRules();
        // nodeToCleanUp.web_service_node.data.additional_settings = JSON.stringify(
        //   nodeToCleanUp.web_service_node.data.add_settings_placeholder
        // );

        delete nodeToCleanUp.web_service_node.data.post_body_placeholder;
        // delete nodeToCleanUp.web_service_node.data.add_settings_placeholder;
        return _.cloneDeep(nodeToCleanUp);
      } catch (err) {
        this.$notify({
          type: "error",
          message: err.message,
          title: "Error"
        });
        this.isSubmitting = false;
        this.toggleNodeSubmit(false);
        return null;
      }
    },
    cleanUpNode() {
      const nodeAfterCleanup = this.cleanUpNodeToPrepareForSubmit();
      if (!_.isEmpty(nodeAfterCleanup)) {
        nodeAfterCleanup.web_service_node.data.variable_rules.data = filterRowsIfSomeKeyValueIsAbsent(
          this.variableRulesBckup,
          "rule_value,variable_name"
        );
        this.nodeToBind = nodeAfterCleanup;
        this.createOrEditNode();
      }
    }
  },
  watch: {
    clickedNode: {
      handler: "initializeWebServiceNode"
    },
    variableRules: {
      deep: true,
      handler(val) {
        this.newVariableCreated = _.some(
          val,
          variable => variable.variable_id === -1
        );
      }
    },
    selectedAccountId: {
      immediate: true,
      async handler(newVal) {
        if (newVal === "all") {
          this.showOpenAiProfile = false;
        } else {
          await this.toggleOpenAIFeatureVisibility();
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
@import "~@/styles/element-variables.scss";
@import "~@/styles/node-palette.scss";
@import "~@/styles/button.scss";
@import "~@/styles/typography.scss";
@import "~@/styles/node_common.scss";
@import "~@/styles/content-buttons.scss";

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-to,
.fade-leave {
  opacity: 1;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 700ms ease-in-out;
}

.backBtn {
  border: none;
  font-size: 14px;
  font-weight: bold;

  ::v-deep i {
    font-size: 18px;
    font-weight: bolder;
  }

  ::v-deep span {
    margin-bottom: 6px;
  }
}

.response {
  margin-top: 20px;

  ::v-deep .el-divider--horizontal {
    margin: 0 0 10px 0;
    background-color: #dadde2;
    height: 2px;
  }
}

.inner-title {
  font-size: 0.75rem;
  font-weight: bold;
}

.json-paths ::v-deep .row-message {
  font-size: 0.75rem;
  color: $--color-success;
  margin-left: 10px;
}
</style>
